import {createNgModuleRef, inject, Injectable, Injector, NgModuleRef, ViewContainerRef} from '@angular/core'
import {FunctionReturningPromise, ModuleConfig, ModulesConfig} from './loadable.types'
import {Logger} from '../logging/logger.types'

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable({
  providedIn: 'root',
})
export class LoadableService {
  public modules: ModulesConfig = []

  private readonly logger = inject(Logger)

  addConfig(config: ModulesConfig) {
    config.forEach(async module => {
      if (this.modules.find(m => m.name === module.name)) {
        return
      }

      this.modules.push(module)
      if (module.preload) {
        await this.preload(module.load)
      }
    })
  }

  getModule(module: string): ModuleConfig {
    const config = this.modules.find(m => m.name === module)

    if (!config) {
      this.logger.error(`${module} lazy module not found`)
      throw new Error(`${module} lazy module not found`)
    }

    return config
  }

  load(module: string, injector: Injector): Promise<NgModuleRef<any>> {
    return this.getModule(module)
      .load()
      .then(Module => createNgModuleRef(Module, injector))
  }

  /**
   * Imports the module
   * @param module
   */
  preload(module: FunctionReturningPromise): Promise<void> {
    return module().then()
  }

  renderComponent(mr: NgModuleRef<any>, vcr: ViewContainerRef) {
    if (!mr) {
      return
    }

    const rootComponent = (mr as any)._bootstrapComponents[0]
    vcr.remove()
    return vcr.createComponent(rootComponent, {injector: mr.injector})
  }
}
