import {
  Component,
  EventEmitter,
  Injector,
  Input,
  NgModuleRef,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import {LoadableService} from './loadable.service'
import {Logger} from '../logging/logger.types'

@Component({
  selector: 'ngx-loadable',
  template: ` <ng-container #container></ng-container> `,
})
export class LoadableComponent implements OnChanges {
  @Input() module!: string
  @Input() show = false
  @Output() init = new EventEmitter()

  @ViewChild('container', {read: ViewContainerRef, static: true}) private container!: ViewContainerRef

  private moduleRef!: NgModuleRef<unknown>

  constructor(
    private readonly inj: Injector,
    private readonly loadableService: LoadableService,
    private readonly logger: Logger,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show?.currentValue) {
      if (this.moduleRef) {
        this.render()
        return
      }
      this.load()
    }
  }

  load() {
    this.loadableService
      .load(this.module, this.inj)
      .then(moduleRef => (this.moduleRef = moduleRef))
      .then(() => this.render())
      .catch(error => this.logger.error(error))
  }

  private render() {
    const componentRef = this.loadableService.renderComponent(this.moduleRef, this.container)
    this.init.next(componentRef)
  }
}
