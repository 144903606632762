import {NgModule, Inject, Optional, ModuleWithProviders, InjectionToken} from '@angular/core'
import {CommonModule} from '@angular/common'
import {LoadableComponent} from './loadable.component'
import {LoadableService} from './loadable.service'
import {ModulesConfig, ILoadableRootConfig} from './loadable.types'

const LOADABLE_CONFIG = new InjectionToken<LoadableService>('LOADABLE_CONFIG')

@NgModule({
  declarations: [LoadableComponent],
  imports: [CommonModule],
  providers: [],
  exports: [LoadableComponent],
})
export class LoadableModule {
  static forRoot(config: ILoadableRootConfig = {}): ModuleWithProviders<LoadableModule> {
    return {
      ngModule: LoadableModule,
      providers: [{provide: LOADABLE_CONFIG, useValue: config.moduleConfigs, multi: true, deps: [LoadableService]}],
    }
  }

  constructor(loadableService: LoadableService, @Optional() @Inject(LOADABLE_CONFIG) configs: ModulesConfig[] = []) {
    if (!configs.length) {
      return
    }

    const moduleConfig = configs[configs.length - 1]
    loadableService.addConfig(moduleConfig)
  }
}
